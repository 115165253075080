<template>
  <div id="app" class="bg-light">
    <div class="main-container">
      <div :class="{'d-none':isLoadingMainApp}">
        <router-view /> 
      </div>
      <div :class="{'d-none':!isLoadingMainApp}" class="h-100vh d-flex justify-content-center align-items-center">
        <b-img center :src="gifLoading" width="100" alt="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import GifLoading from "@/assets/images/loading-d-dots.gif";
import { mapGetters } from "vuex";
// import { useFavicon } from '@vueuse/core'

export default {
  data() {
    return {
      gifLoading: GifLoading,
      brandId: null
    }
  },
  computed: {
    ...mapGetters({
      logo: "getLogo",
      isLoadingMainApp: "getIsLoadingMainApp",
    }),
  },
  // async mounted () {
  //   this.brandId = null;
  //   if(this.brandId) this.getLogo();
  // },
  // watch: {
  //   '$route.query': {
  //     handler: function(newValue) {
  //       this.brandId = newValue.brandId;
  //       if(newValue) this.getLogo();
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    getLogo: async function () {
      await this.$store.dispatch("setIsLoadingMainApp", true);
      await this.$axios.get(`${this.$baseUrl}/system/brandlogo/${this.brandId}`).then(async data => {
        if (data.data.result == 1) {
          await this.$store.dispatch("setIsLoadingMainApp", false);
          const icon = useFavicon();
          icon.value = data.data.detail;
          await this.$store.dispatch("setLogo", data.data.detail);
        } else {
          this.$store.dispatch("setIsLoadingMainApp", false);
        }
      }).catch(error => {
        console.log("error brandlogo", error);
        this.$store.dispatch("setIsLoadingMainApp", false);
      });
    }
  },
}
</script>
<style lang="scss">
.main-container {
  margin: auto;
  min-height: 100vh;
  background-color: white;
  color: black;
}

@media only screen and (min-width: 1200px) {
  // .main-container {
  //   max-width: 800px;
  // }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
